import Vue from 'vue'
import Router from 'vue-router'
import Store from '../store/index.js'
import SignUp from '../pages/SignUp.vue'
import Survey from '../pages/Survey.vue'
import Transactions from '../pages/Transactions.vue'
import Booking from '../pages/Booking.vue'
import TakePicture from '../pages/TakePicture.vue'
import TakePicture2 from '../pages/TakePicture2.vue'
import Login from '../pages/Login.vue'
import VerifyAccount from '../pages/VerifyAccount.vue'
import Forget from '../pages/Forget.vue'
import ChangePassword from '../pages/ChangePassword.vue'
// import SendInquiry from '../pages/SendInquiry.vue'
import OtpInput from '../pages/OtpInput.vue'
import Surveys from '../pages/Surveys.vue'
import JobOrders from '../pages/JobOrders.vue'
import Services from '../pages/Services.vue'
import JobOrderServices from '../pages/JobOrderServices.vue'
import Test from '../pages/Test.vue'
import ShippingLineView from '../pages/ShippingLineView.vue'
import DailyMovementReport from '../pages/DailyMovementReport.vue'
import Invoices from '../pages/Invoices.vue'
import EncodeJobOrderServices from '../pages/EncodeJobOrderServices.vue'
import DamageList from '../pages/DamageList.vue'
import GenerateQrCode from '../pages/GenerateQrCode.vue'
import AttendanceData from '../pages/AttendanceData.vue'
import SwipeScreen from '../pages/SwipeScreen.vue'
import ForWfh from '../pages/ForWfh.vue'
import Receipts from '../pages/Receipts.vue'
import Users from '../pages/Users.vue'
import UsersForPayroll from '../pages/UsersForPayroll.vue'
import Index from '../pages/Index.vue'
import EstimateOfRepair from '../pages/EstimateOfRepair.vue'
import Payroll from '../pages/Payroll.vue'
import Holidays from '../pages/Holidays.vue'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'Inex',
        component: Index,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/sign_up',
        name: 'SignUp',
        component: SignUp,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/verify_account/:email',
        name: 'VerifyAccount',
        component: VerifyAccount,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/forget',
        name: 'Forget',
        component: Forget,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/change_password/:email',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/users_for_payroll',
        name: 'UsersForPayroll',
        component: UsersForPayroll,
        meta: {
            isPublic: true
        }
    },
    // {
    //     path: '/otp_input/:container_number/:transaction_id/:email',
    //     name: 'OtpInput',
    //     component: OtpInput,
    //     meta: {
    //         isPublic: true
    //     }
    // },
    {
        path: '/shipping_line_view',
        name: 'ShippingLineView',
        component: ShippingLineView,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/survey/:tab?/:transaction_id?',
        name: 'Survey',
        component: Survey,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/transactions/:tab?/:transaction_id?',
        name: 'Transactions',
        component: Transactions,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/booking/:container_number?',
        name: 'Containers',
        component: Booking,
        meta: {
            isPublic: false
        }
    },
    // {
    //     path: '/surveys/:tab?/:transaction_id?',
    //     name: 'Surveys',
    //     component: Surveys,
    //     meta: {
    //         isPublic: false
    //     }
    // },
    {
        path: '/job_orders/:tab?/:job_order_id?',
        name: 'JobOrders',
        component: JobOrders,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/services',
        name: 'Services',
        component: Services,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/holidays',
        name: 'Holidays',
        component: Holidays,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/job_order_services',
        name: 'JobOrderServices',
        component: JobOrderServices,
        meta: {
            isPublic: false
        }
    },
    // {
    //     path: '/send_inquiry',
    //     name: 'SendInquiry',
    //     component: SendInquiry,
    //     meta: {
    //         isPublic: false
    //     }
    // },
    // {
    //     path: '/take_picture/:container_number/:transaction_id',
    //     name: 'TakePicture',
    //     component: TakePicture,
    //     meta: {
    //         isPublic: false
    //     }
    // },
    {
        path: '/take_picture2/:container_number/:transaction_id',
        name: 'TakePicture2',
        component: TakePicture2,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/daily_movement_report',
        name: 'DailyMovementReport',
        component: DailyMovementReport,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: Invoices,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/test/:container_number/:transaction_id',
        name: 'Test',
        component: Test,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/encode_job_order_services/:transaction_id/:container_number/:survey_id/:job_order_id/:damage_id',
        name: 'EncodeJobOrderServices',
        component: EncodeJobOrderServices,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/damage_list/:transaction_id/:container_number/:survey_id/:job_order_id/:tab',
        name: 'DamageList',
        component: DamageList,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/generate_qr_code',
        name: 'GenerateQrCode',
        component: GenerateQrCode,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/attendance_data',
        name: 'AttendanceData',
        component: AttendanceData,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/swipe_screen',
        name: 'SwipeScreen',
        component: SwipeScreen,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/for_wfh',
        name: 'ForWfh',
        component: ForWfh,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/payroll',
        name: 'Payroll',
        component: Payroll,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/receipts/:eir_no?',
        name: 'Receipts',
        component: Receipts,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/estimate_of_repair',
        name: 'EstimateOfRepair',
        component: EstimateOfRepair,
        meta: {
            isPublic: false
        }
    },
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(page => page.meta.isPublic || Store.state.isLogged)) {
        next()
    } else {
        next('/login')
    }
});

export default router
