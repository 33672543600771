<template>
    <v-data-table
            :items="items"
            :headers="headers"
            :loading="loading"
            :search="search"
            hide-default-footer
            :items-per-page="itemsPerPage"        
    >
        <template v-slot:top>                      
            <v-text-field
                outlined
                dense
                prepend-inner-icon="mdi-magnify"
                label="Search by text..."
                v-model="search"
            ></v-text-field>
            <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>  
            <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    total-visible="10"
                    dark
                    @input="changePage()"
                ></v-pagination>
            </div>                                       
        </template>
        <template v-slot:[`item.remark`]="{ item }">
            {{ item.out | putRemark  }}
        </template>
        <template v-slot:[`item.attendance`]="{ item }">
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Absent</th>
                        <th>UT</th>
                        <th>Holiday</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
        <template v-slot:[`item.deductions`]="{ item }">
            <v-simple-table>
                <thead>
                    <tr>
                        <th>SSS</th>
                        <th>Philhealth</th>
                        <th>Pag-ibig</th>
                        <th>Withholding tax</th>
                        <th>SS Salary</th>
                        <th>Pag-ibig</th>
                        <th>SS Calamity</th>
                        <th>Company</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
        <template v-slot:footer>
            <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    total-visible="10"
                    dark
                    @input="changePage()"
                ></v-pagination>
            </div>
        </template>
    </v-data-table> 
</template>
<script>
export default {
    props: [
        'items',
        'headers',
        'loading',
        'search',
        'itemsPerPage',
        'totalNumberOfRecords',
        'pageCount',
        'page',
    ],
    filters: {
        putRemark: function( value ) {
            if(!value) {
                return 'Without time-out';
            }
        },        
    }
}
</script>
<style scoped>
th {
    height: 10px;
}
</style>