var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_vm._v(" "),_c('v-card',{staticClass:"mx-auto px-2 pt-2"},[_c('v-toolbar',{staticClass:"indigo lighten-3",attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Payroll")])],1),_vm._v(" "),_c('v-card-text',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-radio-group',{model:{value:(_vm.radios),callback:function ($$v) {_vm.radios=$$v},expression:"radios"}},[_c('v-radio',{attrs:{"label":"Raw Data","value":"rawData"}}),_vm._v(" "),_c('v-radio',{attrs:{"label":"Aggregate Results","value":"aggregateResults"}})],1),_vm._v(" "),(_vm.$store.state.user.role == 'su' || _vm.$store.state.user.role == 'surveyor' || _vm.$store.state.user.role == 'admin')?_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Rnage","prepend-icon":"mdi-calendar","readonly":""},on:{"click":function($event){_vm.dates = []}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on)):_vm._e()]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_vm._v(" "),_c('v-tabs',{attrs:{"center-active":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_vm._v(" "),_vm._l((_vm.remarks),function(remark,i){return _c('v-tab',{key:i},[_vm._v("\n                    "+_vm._s(remark)+"\n                ")])})],2),_vm._v(" "),(_vm.tab == 0)?_c('summary-component',_vm._b({},'summary-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 1)?_c('management-component',_vm._b({},'management-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 2)?_c('supervisors-component',_vm._b({},'supervisors-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 3)?_c('rank-and-file-component',_vm._b({},'rank-and-file-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 4)?_c('new-hires-component',_vm._b({},'new-hires-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 5)?_c('consultant-component',_vm._b({},'consultant-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 6)?_c('bilt-component',_vm._b({},'bilt-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 7)?_c('ojt-component',_vm._b({},'ojt-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e(),_vm._v(" "),(_vm.tab == 8)?_c('payslip-component',_vm._b({},'payslip-component',{
                    items: _vm.items, 
                    headers: _vm.headers, 
                    loading: _vm.loading, 
                    search: _vm.search, 
                    itemsPerPage: _vm.itemsPerPage, 
                    totalNumberOfRecords: _vm.totalNumberOfRecords,
                    pageCount: _vm.pageCount,
                    page: _vm.page,
                },false)):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }