<template>
    <div>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
            <v-container>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title> Picture Uploading Form: {{ containerNumber }} </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <canvas ref="canvas" style="display: none;"></canvas>
                    <v-row>
                        <v-col cols="12">
                            <v-list>
                                <v-toolbar flat dark color="indigo lighten-3 my-2">
                                    <v-toolbar-title>Encode Container details: {{ containerNumber }}</v-toolbar-title>
                                </v-toolbar>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-form ref="form" lazy-validation>
                                        <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="manufacturingDate"
                                                label="ManufacturingDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                :rules="rules"
                                                @input="updateContainerDetails()"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="manufacturingDate"
                                                @input="menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                        <!-- <v-text-field
                                            outlined
                                            dense
                                            v-model="manufacturingDate"
                                            label="ManufacturingDate"
                                            @blur="updateContainerDetails()"
                                        ></v-text-field> -->
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="tareWeight"
                                            label="TareWeight"
                                            :rules="rules"
                                            @blur="updateContainerDetails()"
                                        ></v-text-field>
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="massGrossWeight"
                                            label="MassGrossWeight"
                                            :rules="rules"
                                            @blur="updateContainerDetails()"
                                        ></v-text-field>
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="brokerName"
                                            label="BrokerName"
                                            :rules="rules"
                                            @blur="updateContainerDetails()"
                                        ></v-text-field>
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="plateNumber"
                                            label="PlateNumber"
                                            :rules="rules"
                                            @blur="updateContainerDetails()"
                                        ></v-text-field>
                                        <v-btn
                                            color="red"
                                            class="lighten-2"
                                            block
                                            dark
                                            @click="saveContainerDetails()"
                                        >
                                        <v-icon>mdi-content-save-all</v-icon>
                                        register in database
                                        </v-btn>
                                        </v-form>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-if="showPictureUploadingSlots">
                            <v-btn
                                color="green"
                                dark
                                block
                                v-if="showSubmitPicturesButton"
                                @click="encodeDestinationDialog = true"
                            >
                                <v-icon>mdi-image-move</v-icon>
                                Submit Pictures
                            </v-btn>
                            <v-data-table
                                :headers="areaHeaders"
                                :items="areas"
                                :items-per-page="-1"
                            >
                                <template v-slot:[`item.file_name`]="{ item }">
                                    {{ item.area_type }}-{{ item.area_name }}
                                </template>
                                <template v-slot:[`item.skipped`]="{ item }">
                                    <!-- <v-icon
                                        @click="skipUploading(item)"
                                        dark
                                        class="red lighten-3"
                                        v-show="item.area_name === 'UnderStructure'"
                                    >
                                        mdi-close-octagon
                                    </v-icon> -->
                                    <v-switch
                                        v-if="item.picture != null"
                                        v-model="item.picture.skipped"
                                        v-show="item.area_name === 'UnderStructure'"
                                        @change="toggleSkipped(item)"
                                    ></v-switch>
                                    <v-switch
                                        v-else
                                        :v-model="0"
                                        v-show="item.area_name === 'UnderStructure'"
                                        @change="toggleSkipped(item)"
                                    ></v-switch>
                                </template>
                                <template v-slot:[`item.service_pictures`]="{ item }">
                                        <v-icon
                                            dark
                                            color="green"
                                            v-if="item.picture && !item.picture.skipped"
                                            @click="viewServicePicture(item)"
                                        >mdi-image</v-icon>
                                        <v-icon
                                            color="red"
                                            small
                                            v-if="item.picture && !item.picture.skipped"
                                            @click="deleteServicePicture(item)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    <!-- </v-badge> -->
                                    <v-file-input
                                        dense
                                        outlined
                                        hide-input
                                        v-model="servicePicture"
                                        prepend-icon="mdi-camera"
                                        capture="camera"
                                        accept="image/*"
                                        @change="addServicePicture(item)"
                                        :disabled="item.picture != null && item.picture.skipped == 1"
                                    ></v-file-input>
                                </template>
                                <template v-slot:[`item.damage_pictures`]="{ item }">
                                    <v-badge
                                        color="red"
                                        :content="item.picture ? item.picture.damage_pictures.length : 0"
                                        overlap
                                        v-if="item.picture ? item.picture.damage_pictures.length : 0 > 0 && !item.picture.skipped"
                                    >
                                        <v-icon
                                            color="blue"
                                            @click="openDamagePictureDialog(item)"
                                            v-if="!item.picture.skipped"
                                        >mdi-image</v-icon>
                                    </v-badge>
                                    <v-file-input
                                        dense
                                        outlined
                                        hide-input
                                        v-model="damagePicture"
                                        prepend-icon="mdi-camera"
                                        capture="camera"
                                        accept="image/*"
                                        @change="addDamagePicture(item)"
                                        :disabled="item.picture == null || (item.picture && item.picture.skipped == 1)"
                                    ></v-file-input>
                                </template>
                                <template v-slot:[`item.encode_cedex_code`]="{ item }">
                                    <v-icon
                                        dark
                                        color="blue"
                                        @click="openEncodeCedexCodeDialog(item)"
                                    >mdi-format-list-bulleted-type</v-icon>
                                </template>
                                <template v-slot:[`item.encode_damage_code`]="{ item }">
                                    <div v-if="item.picture ? item.picture.damages.length : 0 > 0">
                                        <v-badge
                                        color="red"
                                            :content="item.picture ? item.picture.damages.length : 0"
                                            overlap
                                        >
                                            <v-icon
                                                dark
                                                color="blue"
                                                @click="openEncodeDamageCodeDialog(item)"
                                            >mdi-format-list-bulleted-type</v-icon>
                                        </v-badge>
                                    </div>
                                    <div v-else>
                                        <v-icon
                                            dark
                                            color="blue"
                                            :disabled="item.picture == null || (item.picture != null && item.picture.skipped == 1)"
                                            @click="openEncodeDamageCodeDialog(item)"
                                        >mdi-format-list-bulleted-type</v-icon>
                                    </div>
                                </template>
                                <template v-slot:[`item.status`]="{ item }">

                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-container>
        </v-card>
        <!-- damagePictureDialog start -->
        <v-dialog v-model="damagePictureDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Damage Pictures -- ContainerNumber: {{containerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="damagePictureDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="damagePictureHeaders"
                            :items="damagePictures"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    color="green"
                                    @click="viewDamagePicture(item)"
                                >mdi-eye</v-icon>
                                <v-icon
                                    color="red"
                                    @click="deleteDamagePicture(item)"
                                >mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--damagePictureDialog end -->
        <!--encodeDamageCodeDialog start -->
        <v-dialog v-model="encodeDamageCodeDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container fluid>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Encode DamageCode: {{ currentPictureFileName }}({{ containerNumber }})</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="encodeDamageCodeDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-form ref="form">
                    <v-card-text>
                  <v-row>
                    <v-col>
                      <v-img
                        :src="`/images/locations/loc-${currentContainerSize}-${currentAreaCode}.png`"
                        width="400"
                      ></v-img>
                      </v-col>
                      <v-col>
                        <a :href="pictureTempUrl" target="_blank">
                            <v-img
                                :src="pictureTempUrl"
                                width="400"
                            ></v-img>
                        </a>
                    </v-col>
                      <v-col v-for="(pic, i) in damagePictureTempUrls" :key="i">
                        <a :href="pic" target="_blank">
                            <v-img
                                :src="pic"
                                width="400"
                            ></v-img>
                        </a>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        dense
                        v-model="currentLocationCode"
                        label="FromLocation"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        dense
                        v-model="currentToLocationCode"
                        label="ToLocation"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        outlined
                        dense
                        v-model="currentDamageCode"
                        :items="damageCodes"
                        item-text="damage_name"
                        item-value="damate_code_id"
                        label="DamageCode"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="red"
                    class="lighten-2"
                    dark
                    block
                    @click="addDamage()"
                    :disabled="currentPictureStatus == 'Finished'"
                  >
                    <v-icon>mdi-plus</v-icon> ADD
                  </v-btn>
                </v-card-actions>
                </v-form>
                <v-card-text>
                    <v-data-table
                        :headers="damagesHeaders"
                        :items="damages"
                    >
                        <template v-slot:top>
                            <v-toolbar flat dark class="indigo lighten-3">
                            <v-toolbar-title>Damages to be registered</v-toolbar-title>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.damage_id`]="{ item }">
                            <span
                                v-if="item.damage_id == damageId"
                                style="background-color: yellow;"
                            >
                                {{ item.damage_id }}
                            </span>
                            <span
                                v-else
                            >
                                {{ item.damage_id }}
                            </span>

                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                            color="red"
                            @click="deleteDamage(item)"
                            :disabled="currentPictureStatus == 'Finished'"
                            >mdi-delete</v-icon>
                        </template>
                        <template v-slot:[`item.encode_job_order_services`]="{ item }">
                            <div v-if="item.job_order_services.length > 0">
                                <v-badge
                                    color="red"
                                    overlap
                                    :content="item.job_order_services.length"
                                >
                                    <v-btn
                                        color="orange"
                                        dark
                                        small
                                        @click="selectDamageForJobOrderService(item)"
                                    >
                                        <v-icon>mdi-toolbox</v-icon>ENCODE
                                    </v-btn>
                                </v-badge>
                            </div>
                            <div v-else>
                                <v-btn
                                    color="orange"
                                    dark
                                    small
                                    @click="selectDamageForJobOrderService(item)"
                                >
                                    <v-icon>mdi-toolbox</v-icon>ENCODE
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <!-- </v-container>
            </v-card>
            <v-card flat>
                <v-container fluid> -->
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Encode Job Order Services: {{ damageId }}</v-toolbar-title
                        >
                    </v-toolbar>
                    <!-- <v-card-actions>
                        <v-btn
                            block
                            color="green"
                            dark
                            @click="allDoneForThisDamage(currentDamage)"
                            v-if="jobOrderServices.length > 0"
                        >
                            <v-icon>mdi-check-outline</v-icon> All Done For This Damage
                        </v-btn>
                    </v-card-actions> -->
                    <v-card-text>
                        <v-autocomplete
                            label="Part Name"
                            dense
                            outlined
                            chips
                            small-chips
                            v-model="partName"
                            :items="part_names"
                            @change="getDescriptions"
                            :disabled="!damageId"
                        ></v-autocomplete>
                        <v-autocomplete
                            label="Description"
                            dense
                            outlined
                            chips
                            small-chips
                            v-model="description"
                            :items="descriptions"
                            :disabled="!damageId"
                            @change="getLocations"
                        ></v-autocomplete>
                        <v-autocomplete
                            label="Location"
                            dense
                            outlined
                            chips
                            small-chips
                            v-model="location"
                            :items="locations"
                            :disabled="!damageId"
                            @change="getWayOfRepairs"
                        ></v-autocomplete>
                        <v-divider></v-divider>
                        <v-card-title>Way of Repair</v-card-title>
                        <v-chip-group
                            active-class="primary--text"
                            column
                            v-model="wayOfRepair"
                        >
                            <v-chip
                            v-for="id, w in way_of_repairs"
                            :key="id"
                            dark
                            @click="getServiceId(id)"
                            >
                            {{ w }}
                            </v-chip>
                        </v-chip-group>
                        <v-btn
                            fab
                            fixed
                            bottom
                            right
                            dark
                            color="red"
                            @click="saveJobOrderServices"
                            :disabled="wayOfRepair === null"
                        >
                            <v-icon>fas fa-save</v-icon>
                        </v-btn>
                        <v-divider></v-divider>
                        <v-data-table
                            :headers="servicesHeaders"
                            :items="jobOrderServices"
                            :items-per-page="-1"
                            :loading="loading"
                        >

                            <!-- <template v-slot:[`item.post_service_pictures`]="{ item }">
                                <div v-if="item.post_service_pictures.length > 0">
                                    <v-badge
                                        color="red"
                                        :content="item.post_service_pictures.length"
                                        overlap
                                    >
                                        <v-btn
                                            color="orange"
                                            small
                                            dark
                                            @click="openPostServicePicturesDialog(item)"
                                        >
                                            <v-icon>mdi-image</v-icon>
                                            Post Service Pictures
                                        </v-btn>
                                    </v-badge>
                                </div>
                                <div v-else>
                                    <v-btn
                                        color="orange"
                                        small
                                        dark
                                        @click="openPostServicePicturesDialog(item)"
                                    >
                                        <v-icon>mdi-image</v-icon>
                                        Post Service Pictures
                                    </v-btn>
                                </div>
                            </template> -->
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        color="red"
                                        v-on="on"
                                        @click="deleteJobOrderService(item)"
                                        :disabled="item.post_service_pictures.length > 0"
                                    >mdi-delete</v-icon>
                                </template>
                                <span>Delete JobOrderService</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--encodeDamageCodeDialog end -->
        <!--encodeDestinationDialog start-->
        <v-dialog v-model="encodeDestinationDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container fluid>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Encode Destination: {{ containerNumber }}</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="encodeDestinationDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-actions>
                        <v-select
                            label="Status"
                            :items="remarks"
                            item-text="remark_name"
                            item-value="remark_id"
                            outlined
                            dense
                            v-model="remark_id"
                        ></v-select>
                    </v-card-actions>
                    <v-card-actions>
                        <v-textarea
                            readonly
                            outlined
                            dense
                            label="CEDEX Code"
                            v-model="cedexCode"
                        ></v-textarea>
                    </v-card-actions>
                    <v-card-actions>
                        <v-select
                            label="Repair Destination"
                            :items="destinations"
                            item-text="destination_type"
                            item-value="destination_id"
                            outlined
                            dense
                            v-model="destination"
                        ></v-select>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn
                            v-if="showSubmitPicturesButtonInside"
                            color="green"
                            block
                            dark
                            @click="submitPictures"
                        >
                            <v-icon>mdi-image-move</v-icon>submit pictures & print out receipt
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!--encodeDestinationDialog end-->
        <!--encodeCedexCodeDialog start-->
        <v-dialog v-model="encodeCedexCodeDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container fluid>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Encode CEDEX Code: {{ containerNumber }}</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="encodeCedexCodeDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-actions>
                        <v-textarea
                            outlined
                            dense
                            label="CEDEX Code"
                            v-model="cedexCode"
                        ></v-textarea>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn 
                            block 
                            color="green" 
                            dark
                            @click="updateCedexCode()"
                        >
                            <v-icon>mdi-update</v-icon> update cedex code
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!--encodeCedexCodeDialog end-->
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            containerNumber: null,
            transactionId: null,
            rules: [(v) => !!v || "Required!"],
            menu: false,
            manufacturingDate: null,
            tareWeight: null,
            massGrossWeight: null,
            brokerName: null,
            plateNumber: null,
            transactions: [],
            areaHeaders: [
            {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Skipped",
                    value: "skipped",
                    sortable: true,
                },
                {
                    text: "ServicePicture(s)",
                    value: "service_pictures",
                    sortable: false,
                },
                {
                    text: "DamagePicture(s)",
                    value: "damage_pictures",
                    sortable: false,
                },
                {
                    text: "Encode CEDEX Code",
                    value: "encode_cedex_code",
                    sortable: false,
                },
                {
                    text: "Encode DamageCode",
                    value: "encode_damage_code",
                    sortable: false,
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: false,
                },
            ],
            areas: [],
            servicePictures: [],
            damagePictures: [],
            servicePicture: null,
            damagePicture: null,
            formData: null,
            showPictureUploadingSlots: false,
            damagePictureDialog: false,
            damagePictureHeaders: [
                {
                    text: 'FileName',
                    value: 'file_name',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: true,
                },
                {
                    text: 'CreatedAt',
                    value: 'created_at',
                    sortable: true,
                }
            ],
            encodeDamageCodeDialog: false,
            currentPictureFileName: null,
            currentContainerSize: null,
            currentAreaCode: null,
            currentPictureStatus: null,
            currentLocationCode: null,
            currentToLocationCode: null,
            currentDamageCode: null,
            currentDamage: null,
            currentPictureItem: null,
            pictureTempUrl: null,
            damagePictureTempUrls: null,
            // locationCodes: [],
            // toLocationCodes: [],
            damageCodes: [],
            damagesHeaders: [
                {
                    text: "DamageId",
                    value: "damage_id",
                    sortable: true,
                },
                {
                    text: "AreaCode",
                    value: "area_code",
                    sortable: true,
                },
                {
                    text: "FromLocation",
                    value: "location_code",
                    sortable: true,
                },
                {
                    text: "ToLocation",
                    value: "to_location_code",
                    sortable: true,
                },
                {
                    text: "DamageCode",
                    value: "damage_code",
                    sortable: true,
                },
                {
                    text: "EncodeJobOrderServices",
                    value: "encode_job_order_services",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: true,
                },
            ],
            damages: [],
            statusIcons: {
                'Not Yet Started': {
                    icon: 'mdi-stop',
                    color: 'red'
                },
                'Ongoing': {
                    icon: 'mdi-play',
                    color: 'blue'
                },
                'Finished': {
                    icon: 'mdi-check',
                    color: 'green'
                }
            },
            jobOrderId: null,
            jobOrderServices: [],
            part_names: [],
            partName: [],
            descriptions: [],
            description: null,
            locations: [],
            location: null,
            way_of_repairs: [],
            wayOfRepair: null,
            servicesHeaders: [
                {
                    text: "JobOrderServiceId",
                    value: "job_order_service_id",
                    sortable: true,
                },
                {
                    text: "ServiceId",
                    value: "service_id",
                    sortable: true,
                },
                {
                    text: "PartName",
                    value: "service.part_name",
                    sortable: true,
                },
                {
                    text: "Description",
                    value: "service.description",
                    sortable: true,
                },
                {
                    text: "Loacation",
                    value: "service.location",
                    sortable: true,
                },
                {
                    text: "WayOfRepair",
                    value: "service.way_of_repair",
                    sortable: true,
                },
                {
                    text: "Hour",
                    value: "service.hour",
                    sortable: true,
                },
                {
                    text: "MaterialCost",
                    value: "service.material_cost",
                    sortable: true,
                },
                {
                    text: "LaborCost",
                    value: "service.labor_cost",
                    sortable: true,
                },
                // {
                //     text: "Post-Service Pictures",
                //     value: "post_service_pictures",
                //     sortable: false,
                // },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                }
            ],
            damageId: null,
            remarks: [],
            remark_id: null,
            cedexCode: null,
            destinations: [],
            destination: null,
            encodeDestinationDialog: false,
            showSubmitPicturesButton: false,
            receipt: null,
            encodeCedexCodeDialog: false,
        }
    },
    mounted() {
        if(this.$store.state.user.role === 'admin') {
            location.href = '/transactions';
        }

        this.containerNumber = this.$route.params.container_number;
        this.transactionId = this.$route.params.transaction_id;

        this.loadAreas();
        this.getTransaction();
        this.loadRemarks();
        this.loadDestinations();
    },
    methods: {
        getTransaction: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_transaction',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.transactions = response.data;
                    if( this.transactions[0].survey ) {
                        this.surveyStatusCode = this.transactions[0].survey.status_code;
                    }
                    this.currentContainerSize = this.transactions[0]['container']['size']['size'];
                    this.manufacturingDate = this.transactions[0]['manufacturing_date'];
                    this.currentSurveyId = this.transactions[0]['survey_id'];
                    this.tareWeight = this.transactions[0]['tare_weight'];
                    this.massGrossWeight = this.transactions[0]['mass_gross_weight'];
                    this.brokerName = this.transactions[0]['broker_name'];
                    this.plateNumber = this.transactions[0]['plate_number'];

                    this.createJobOrder();

                    if(
                        this.manufacturingDate
                        &&
                        this.tareWeight
                        &&
                        this.massGrossWeight
                        &&
                        this.brokerName
                        &&
                        this.plateNumber
                    ) {
                        this.showPictureUploadingSlots = true;
                    } else {
                        this.showPictureUploadingSlots = false;
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        updateContainerDetails: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/update_container_details',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                    user_id: this.$store.state.user.id,
                    manufacturing_date: this.manufacturingDate,
                    tare_weight: this.tareWeight,
                    mass_gross_weight: this.massGrossWeight,
                    broker_name: this.brokerName,
                    plate_number: this.plateNumber,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    this.getTransaction();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        loadAreas: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_areas2',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                }
            ).then(response => {
                // console.log(response.data)
                // return false;
                this.areas = response.data;

                const numberOfServicePictures = this.areas.filter(function(value) {
                    return value.picture != null;
                });

                if( numberOfServicePictures.length == this.areas.length ) {
                    // alert('Execute SubmitPictures Method!!');
                    this.showSubmitPicturesButton = true;
                } else {
                    this.showSubmitPicturesButton = false;
                }

            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        toggleSkipped: function(item) {

            let skipped = 0;

            if(item.picture) {
                skipped = 0;
            } else {
                skipped = 1;
            }

            this.formData = new FormData();
            this.formData.append("user_id", this.$store.state.user.id);
            this.formData.append('container_number', this.containerNumber);
            this.formData.append('transaction_id', this.transactionId);
            this.formData.append('area_code', item.area_code);
            this.formData.append('area_type', item.area_type);
            this.formData.append('area_name', item.area_name);
            this.formData.append('skipped', skipped);

            this.overlay = true;
            this.axios
                .post(
                "/api/skip_file",
                this.formData,
                {
                    onUploadProgress: this.onUpload,
                },
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {
                    this.loadAreas();
                    this.overlay = false;
                }
            });
        },
        addServicePicture: function(item) {
            this.formData = new FormData();

            if( typeof this.servicePicture === 'undefined' ) {
                return false;
            }

            if (!this.servicePicture.name.match(/png|jpg$/i)) {
                const payload = {
                    title: "Invalid file chosen..",
                    msg: "You tried to upload invalid type of file...you can upload png/jpg only..",
                };
                this.$store.commit("showAlert", payload);
                return false;
            } else {
                // this.formData.append("file", this.servicePicture);
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');
                const img = new Image();
                const self = this;
                let NEW_SIZE = 800;

                img.onload = function() {
                    const aspectRatio = img.naturalHeight / img.naturalWidth;
                    canvas.width = NEW_SIZE;
                    canvas.height = NEW_SIZE * aspectRatio;
                    ctx.drawImage(img, 0, 0, NEW_SIZE, NEW_SIZE * aspectRatio);
                    const imageData = canvas.toDataURL('image/jpeg');
                    imageData.replace('data:image/jpeg;base64,', '');
                    let blobToFile = self.dataURItoBlob(imageData);
                    const cfile = new File([blobToFile], "test.jpeg",
                        {
                            type: "image/jpeg",
                            lastModified: Date.now()
                        }
                    );
                    self.formData.append('file', cfile);
                    self.formData.append("user_id", self.$store.state.user.id);
                    self.formData.append('container_number', self.containerNumber);
                    self.formData.append('transaction_id', self.transactionId);
                    self.formData.append('area_code', item.area_code);
                    self.formData.append('area_type', item.area_type);
                    self.formData.append('area_name', item.area_name);

                    self.overlay = true;
                    self.loading = true;

                    self.axios
                        .post(
                        "/api/save_file",
                        self.formData,
                        {
                            onUploadProgress: this.onUpload,
                        },
                        {
                            headers: {
                            "Content-Type": "multipart/form-data",
                            },
                        }
                        )
                        .then((response) => {
                        if (response.status === 200) {
                            self.loadAreas();
                            self.servicePicture = null;
                        }
                    }).catch(error => {
                        console.log(error);
                    }).finally(() => {
                        self.overlay = false;
                        self.loading = false;
                    });

                }
                img.src = URL.createObjectURL(this.servicePicture);
            }
        },
        dataURItoBlob: function(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var _ia = new Uint8Array(arrayBuffer);
            for (var i = 0; i < byteString.length; i++) {
                _ia[i] = byteString.charCodeAt(i);
            }
            var dataView = new DataView(arrayBuffer);
            var blob = new Blob([dataView], {type: mimeString});
            return blob;
        },
        addDamagePicture: function(item) {
            this.formData = new FormData();

            if( typeof this.damagePicture === 'undefined' ) {
                return false;
            }

            if (!this.damagePicture.name.match(/png|jpg$/i)) {
                const payload = {
                    title: "Invalid file chosen..",
                    msg: "You tried to upload invalid type of file...you can upload png/jpg only..",
                };
                this.$store.commit("showAlert", payload);
                return false;
            } else {
                // this.formData.append("file", this.servicePicture);
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');
                const img = new Image();
                const self = this;
                let NEW_SIZE = 800;

                img.onload = function() {
                    const aspectRatio = img.naturalHeight / img.naturalWidth;
                    canvas.width = NEW_SIZE;
                    canvas.height = NEW_SIZE * aspectRatio;
                    ctx.drawImage(img, 0, 0, NEW_SIZE, NEW_SIZE * aspectRatio);
                    const imageData = canvas.toDataURL('image/jpeg');
                    imageData.replace('data:image/jpeg;base64,', '');
                    let blobToFile = self.dataURItoBlob(imageData);
                    const cfile = new File([blobToFile], "test.jpeg",
                        {
                            type: "image/jpeg",
                            lastModified: Date.now()
                        }
                    );
                    self.formData.append('file', cfile);
                    self.formData.append("user_id", self.$store.state.user.id);
                    self.formData.append('container_number', self.containerNumber);
                    self.formData.append('picture_id', item.picture.id);
                    self.formData.append('transaction_id', self.transactionId);
                    self.formData.append('area_code', item.area_code);
                    self.formData.append('area_type', item.area_type);
                    self.formData.append('area_name', item.area_name);

                    self.overlay = true;
                    self.loading = true;

                    self.axios
                        .post(
                        "/api/save_damage_picture",
                        self.formData,
                        {
                            onUploadProgress: this.onUpload,
                        },
                        {
                            headers: {
                            "Content-Type": "multipart/form-data",
                            },
                        }
                        )
                        .then((response) => {
                        if (response.status === 200) {
                            self.loadAreas();
                            self.damagePicture = null;
                        }
                    }).catch(error => {
                        console.log(error);
                    }).finally(() => {
                        self.overlay = false;
                        self.loading = false;
                    });

                }
                img.src = URL.createObjectURL(this.damagePicture);
            }
        },
        // openServicePictureDialog: function(item) {
        //     this.servicePictureDialog = true;
        // },
        openDamagePictureDialog: function(item) {
            // console.log(item)
            this.damagePictureDialog = true;
            this.getDamagePictures(item);
        },
        viewServicePicture: function(item) {
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_picture",
                    {
                        transaction_id: this.transactionId,
                        container_number: this.containerNumber,
                        file_name: item.picture.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },
        deleteServicePicture: function(item) {
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_picture',
                {
                    id: item.picture.id,
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    file_name: item.picture.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Picture deleted",
                        msg: "The picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.loadAreas();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getDamagePictures: function(item) {
            // console.log(item)
            let picture_id = '';
            if ( item.picture ) {
                picture_id = item.picture.id;
            } else {
                picture_id = item.picture_id;
            }

            this.axios
                .post(
                "/api/get_damage_pictures",
                {
                    picture_id: picture_id,
                }
                ).then((response) => {
                if (response.status === 200) {
                    this.damagePictures = response.data;
                }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        saveContainerDetails: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            this.updateContainerDetails();
        },
        createJobOrder: function() {
            this.axios.post(
                '/api/create_job_order',
                {
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                    container_number: this.containerNumber,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    // const payload = {
                    //     title: "New Job Order created!",
                    //     msg: "You have created a new job order successfully...",
                    // };
                    // this.$store.commit("showAlert", payload);
                    // location.href = '/job_orders';
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        viewDamagePicture: function(item) {
            // console.log(item)
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_damage_picture",
                    {
                        transaction_id: this.transactionId,
                        container_number: this.containerNumber,
                        file_name: item.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },
        deleteDamagePicture: function(item) {
            // console.log(item)
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_damage_picture',
                {
                    id: item.id,
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    file_name: item.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Damage Picture deleted",
                        msg: "The damage picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.loadAreas();
                    this.getDamagePictures(item);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        openEncodeDamageCodeDialog: function(item) {
            this.currentPictureItem = item;
            this.encodeDamageCodeDialog = true;
            this.currentPictureFileName = item.picture.file_name;
            this.getJobOrderId();
            this.getDamages(item);
            this.getPartNames();
            // this.axios.post(
            //     '/api/get_area_configuration',
            //     {
            //     container_size: this.currentContainerSize,
            //     area_code: this.currentAreaCode,
            //     }
            // ).then(response => {
            //     this.locationCodes = [];
            //     this.toLocationCodes = [];
            //     const config = JSON.parse(response.data[0].config);
            //     for(var n = config[0][1]; n <= config[0][2]; n++) {
            //     this.locationCodes.push(
            //             config[0][0] + n
            //         );
            //     }
            //     // console.log(this.locationCodes)
            //     for(var n = config[1][1]; n <= config[1][2]; n++) {
            //         this.toLocationCodes.push(
            //             config[1][0] + n
            //         );
            //     }
            //     // console.log(this.toLocationCodes);
            // }).catch(error => {
            //     console.log(error);
            // });

            this.axios.post('/api/get_damage_codes')
                .then(response => {
                this.damageCodes = response.data;
                }).catch(error => {
                console.log(error);
                });
        },
        addDamage: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            if(!this.currentSurveyId) {
                alert('You need to submit pictures first!');
                return false;
            }
            // console.log(this.curentSurveyId)
            this.overlay = true;
            this.loading = true;
            // console.log(this.currentPictureItem)
            // return false;
            this.axios.post(
                '/api/add_damage',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                    area_code: this.currentPictureItem.area_code,
                    picture_id: this.currentPictureItem.picture.id,
                    location_code: this.currentLocationCode,
                    to_location_code: this.currentToLocationCode,
                    damage_code: this.currentDamageCode,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                // console.log(response.data);
                this.damages = response.data;
                this.getPicturesByTransactionId(this.transactionId);
                this.loadAreas();
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getJobOrderId() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_job_order_id',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                },
            ).then(response => {
                this.jobOrderId = response.data.job_order_id;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getPicturesByTransactionId: function(transaction_id) {
            this.axios.post(
                '/api/get_pictures_by_transaction_id',
                {
                transaction_id: transaction_id,
                }
            ).then(response => {
                // console.log(response.data);
                this.pictures = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getDamages: function(item) {
            this.axios.post(
                '/api/get_damages',
                {
                transaction_id: this.transactionId,
                survey_id: this.currentSurveyId,
                container_number: this.containerNumber,
                area_code: item.area_code,

                }
            ).then(response => {
                // console.log(response.data)
                this.damages = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
        deleteDamage: function(item) {
            if(!confirm('Is it ok to delete the damage information?')) {
                return false;
            }

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/delete_damage',
                    {
                    id: item.id,
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                    container_number: this.containerNumber,
                    area_code: item.area_code,
                    }
                ).then(response => {
                    if(response.status === 200) {
                    this.getDamages(item);
                    this.getPicturesByTransactionId(this.transactionId);
                    this.loadAreas();
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getPartNames: function() {
            this.axios.post('/api/get_part_names')
                .then(response => {
                    // console.log(response.data)
                    if(response.status === 200) {
                        this.part_names = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getDescriptions: function() {
            this.locations = [];
            this.way_of_repairs = [];
            this.description = null;
            this.axios.post(
                '/api/get_descriptions',
                {
                    partName: this.partName,
                }
            ).then(response => {
                // console.log(response.data);
                this.descriptions = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
        getLocations: function() {
            this.way_of_repairs = [];
            this.axios.post(
                '/api/get_locations',
                {
                    partName: this.partName,
                    description: this.description
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.locations = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getWayOfRepairs: function(location) {
            this.location = location;
            this.axios.post(
                '/api/get_way_of_repairs',
                {
                    partName: this.partName,
                    description: this.description,
                    location: location,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.way_of_repairs = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        saveJobOrderServices: function() {
            // if(!confirm('Is it ok to save the data?')) {
            //     return false;
            // }

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/save_job_order_services',
                {
                    jobOrderId: this.jobOrderId,
                    damage: this.currentDamage,
                    partName: this.partName,
                    destination: this.destination,
                    location: this.location,
                    serviceId: this.serviceId,
                    user_id: this.$store.state.user.id
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.getJobOrderServicesByDamageId(this.jobOrderId, this.damageId);
                    this.getDamages(this.currentPictureItem);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getServiceId: function(id) {
            this.serviceId = id;
        },
        selectDamageForJobOrderService: function(item) {
            // console.log(item);
            this.currentDamage = item;
            this.damageId = item.damage_id;
            this.getJobOrderServicesByDamageId(this.jobOrderId, this.damageId);
        },
        getJobOrderServicesByDamageId: function(jobOrderId, damageId) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_job_order_services_by_damage_id',
                {
                    job_order_id: jobOrderId,
                    damage_id: damageId,
                }
            ).then(response => {
                // console.log(response.data);
                this.jobOrderServices = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        deleteJobOrderService: function(item) {
            if(!confirm('Is it ok to delete the JobOrderService?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_job_order_service',
                {
                    id: item.id,
                    job_order_id: item.job_order_id,
                    damage_id: item.damage_id,
                    jobOrder: this.currentJobOrder,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.getJobOrderServicesByDamageId(this.jobOrderId, this.damageId);
                    this.getDamages(this.currentPictureItem);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        loadRemarks: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_remarks',
            ).then(response => {
                this.remarks = response.data;
                // console.log(this.remarks)
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadDestinations: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_destinations'
            ).then(response => {
                this.destinations = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        submitPictures: function() {
            const self = this;

            const destinationTypeObject = this.destinations.filter(function(value) {
                return value.destination_id == self.destination;
            });

            this.receipt = {
                "eir_no": this.transactions[0].transaction_id,
                "date": moment().format(),
                "shipping_line": this.transactions[0].company.name,
                "container_number": this.transactions[0].container_number,
                "container_size": this.transactions[0].container.size.size,
                "container_type": this.transactions[0].container.type.type,
                "container_class": this.transactions[0].container.class.class,
                "manufacturing_date": this.transactions[0].manufacturing_date,
                "mass_gross_weight": this.transactions[0].mass_gross_weight,
                "tare_weight": this.transactions[0].tare_weight,
                "cedex_code": this.cedexCode == null ? '*None' : this.cedexCode,
                "broker_name": this.transactions[0].broker_name,
                "plate_number": this.transactions[0].plate_number,
                "destination_type": destinationTypeObject[0].destination_type,
                "surveyor_name": this.transactions[0].survey.created_by.name,
            };

            this.createReceipt();

            if( this.remark_id === 'R-01' && this.destination === null ) {
                alert('Select any destination!');
                return false;
            }

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/submit_pictures',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                    user_id: this.$store.state.user.id,
                    remark_id: this.remark_id,
                    survey_id: this.currentSurveyId,
                    destination_id: this.destination,
                    cedexCode: this.cedexCode,
                }
            ).then(response => {
                if(response.status === 200) {
                    const payload = {
                        title: "Pictures Submitted!",
                        msg: "The pictures have been successfully submitted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.$router.push('/receipts/' + this.transactions[0].transaction_id);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        createReceipt: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/create_receipt',
                {
                    receipt: this.receipt,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });            
        },
        openEncodeCedexCodeDialog: function(item) {
            this.encodeCedexCodeDialog = true;
            this.getCedexCode();
        },
        getCedexCode: function() {
            this.axios.post(
                '/api/get_cedex_code',
                {
                    transaction_id: this.$route.params.transaction_id,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data[0]);
                    this.cedexCode = response.data[0];
                }
            }).catch(error => {
                console.log(error);
            });
        },
        updateCedexCode: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_cedex_code',
                {
                    transaction_id: this.$route.params.transaction_id,
                    cedex_code: this.cedexCode,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    this.encodeCedexCodeDialog = false;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
    },
    computed: {
        showSubmitPicturesButtonInside: function() {
            // if( this.remark_id && this.cedexCode && this.destination ) {
            if( this.remark_id && this.destination ) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>
