<template>
    <v-container>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Select Company & Date Range</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-select
                    outlined
                    dense
                    v-model="shippingLine"
                    label="ShippingLine"
                    :items="companies"
                    item-text="name"
                    item-value="company_id"
                    prepend-icon="mdi-ferry"
                ></v-select>
            </v-card-text>
            <v-card-text>
                <v-tabs
                    v-model="tab"
                    center-active
                    dark
                >
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab
                        v-for="(tabIndication, i) in tabIndications"
                        :key="i"
                    >
                        {{ tabIndication }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-data-table
                            :items="txns"
                            :headers="txnHeaders"
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"

                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Week#"
                                                    dense
                                                    outlined
                                                    prepend-icon="mdi-calendar-week"
                                                    readonly
                                                    v-model="weekNumber"
                                                    class="mt-3"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="dateRangeText"
                                                    dense
                                                    outlined
                                                    label="Date Rnage"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="dates = []"
                                                    class="mt-3"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <v-date-picker
                                        v-model="dates"
                                        range
                                    ></v-date-picker>
                                </v-menu>
                                <v-btn
                                    block
                                    color="green"
                                    class="my-2"
                                    dark
                                    @click="registerInvoice()"
                                    v-if="txns.length > 0"
                                >
                                    <v-icon>mdi-content-save</v-icon>
                                        REGISTER INVOICE
                                </v-btn>
                                <!-- <v-text-field
                                    class="my-2"
                                    prepend-inner-icon="mdi-magnify"
                                    v-model="search"
                                    outlined
                                    dense
                                ></v-text-field> -->
                                <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
                            </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                                total-visible="10"
                                dark
                                @input="changePage()"
                            ></v-pagination>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
                        <v-data-table
                            :items="invoices"
                            :headers="invoiceHeaders"
                            class="mt-3"
                            hide-default-footer
                        >
                            <template v-slot:[`item.invoice_name`] ="{item}">
                                {{ item.invoice_name }} <v-icon color="orange" @click="openInvoiceNameEditDialog(item)">mdi-pencil</v-icon>
                            </template>
                            <template v-slot:[`item.invoice_number_repair1`] ="{item}">
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>SURVEY_DAF1</th>
                                            <th>WASHING1</th>
                                            <th>REPAIR1</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ item.invoice_number_survey_daf1 }}</td>
                                            <td>{{ item.invoice_number_washing1 }}</td>
                                            <td>{{ item.invoice_number_repair1 }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>SURVEY_DAF2</th>
                                            <th>WASHING2</th>
                                            <th>REPAIR2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ item.invoice_number_survey_daf2 }}</td>
                                            <td>{{ item.invoice_number_washing2 }}</td>
                                            <td>{{ item.invoice_number_repair2 }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </template>
                            <template v-slot:[`item.void`] ="{item}">
                                <v-switch
                                    v-model="item.void"
                                    @change="toggleVoid(item)"
                                ></v-switch>
                            </template>
                            <template v-slot:[`item.download_invoice`] ="{item}">
                                <v-btn
                                    color="indigo"
                                    class="lighten-2"
                                    dark
                                    @click="downloadInvoice(item)"
                                    :disabled="item.void == 1"
                                >
                                    <v-icon>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    Download Invoice
                                </v-btn>

                            </template>
                            <template v-slot:[`item.created_at`] ="{item}">
                                {{ item.created_at | formatDate }}
                            </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                                total-visible="10"
                                dark
                                @input="changePage()"
                            ></v-pagination>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <!-- invoiceNameEditDialog start -->
        <v-dialog v-model="invoiceNameEditDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Edit InvoiceName & Invoice#'s --{{ currentInvoiceId }}--</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="invoiceNameEditDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="InvoiceName"
                                    v-model="invoiceName"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Invoice#_Survey_DAF1"
                                    v-model="invoiceNumberSurveyDaf1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Invoice#_Washing1"
                                    v-model="invoiceNumberWashing1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Invoice#_Repair1"
                                    v-model="invoiceNumberRepair1"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Invoice#_Survey_DAF2"
                                    v-model="invoiceNumberSurveyDaf2"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Invoice#_Washing2"
                                    v-model="invoiceNumberWashing2"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Invoice#_Repair2"
                                    v-model="invoiceNumberRepair2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="red"
                            class="lighten-2"
                            block
                            dark
                            @click="updateInvoiceNumbers()"
                        >
                            <v-icon>mdi-update</v-icon>
                            UPDATE
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- invoiceNameEditDialog end -->
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            shippingLine: 'CLI-0002',
            tab: 0,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            tabIndications: [
                'Transactions',
                'Invoices'
            ],
            search: null,
            menu: false,
            weekNumber: moment().week(),
            dates: [
                moment().day("Sunday").format('YYYY-MM-DD'),
                moment().day("Saturday").format('YYYY-MM-DD')
            ],
            companies: [],
            txnHeaders: [
                {
                    text: "GateOut",
                    value: "job_order.gate_out_at",
                    sortable: false,
                },
                {
                    text: "Container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "Company",
                    value: "container.company.name",
                    sortable: false,
                },
                {
                    text: "ContainerSize",
                    value: "container.size.size",
                    sortable: false,
                },
                {
                    text: "ContainerType",
                    value: "container.type.type",
                    sortable: false,
                },
                {
                    text: "ContainerClass",
                    value: "container.class.class",
                    sortable: false,
                },
                {
                    text: "MfgDate",
                    value: "manufacturing_date",
                    sortable: false,
                },
            ],
            txns: [],
            invoiceHeaders: [
                {
                    text: "InvoiceId",
                    value: "invoice_id",
                    sortable: false,
                },
                {
                    text: "InvoiceName",
                    value: "invoice_name",
                    sortable: false,
                },
                {
                    text: "CompanyId",
                    value: "company_id",
                    sortable: false,
                },
                {
                    text: "Week#",
                    value: "week_no",
                    sortable: false,
                },
                {
                    text: "Invoice#'s",
                    value: "invoice_number_repair1",
                    sortable: false,
                },
                {
                    text: "Download",
                    value: "download_invoice",
                    sortable: false,
                },
                {
                    text: "Void",
                    value: "void",
                    sortable: false,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false,
                },
            ],
            invoices: [],
            invoiceName: null,
            invoiceNameEditDialog: false,
            currentInvoiceId: null,
            invoiceNumberSurveyDaf1: null,
            invoiceNumberSurveyDaf2: null,
            invoiceNumberWashing1: null,
            invoiceNumberWashing2: null,
            invoiceNumberRepair1: null,
            invoiceNumberRepair2: null,
        }
    },
    mounted() {
        if(this.$store.state.user.role === 'admin') {
            location.href = '/transactions';
        }
        this.getTransactionsForInvoices();
        this.getInvoices();
        this.getCompanies();
    },
    methods: {
        getCompanies: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_companies',
            ).then(response => {
                this.companies = response.data;
                // console.log(response.data);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getTransactionsForInvoices: function() {
            // console.log(this.dates)
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_transactions_for_invoices',
                {
                    company_id: this.shippingLine,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    date_from: this.dates[0],
                    date_to: this.dates[1],
                }
            ).then(response => {
                // console.log(response.data)
                this.txns = response.data.data;
                this.totalNumberOfRecords = response.data.total;
                this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getInvoices: function() {
            // console.log(this.shippingLine)
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_invoices',
                {
                    company_id: this.shippingLine,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                }
            ).then(response => {
                this.invoices = response.data.data;
                this.totalNumberOfRecords = response.data.total;
                this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        registerInvoice: function() {
            if(!this.shippingLine) {
                alert('Select any company...');
                return false;
            }

            if(this.dates.length == 0) {
                alert('Select date range...');
                return false;
            }

            this.invoiceName = prompt('Input Invoice #...');

            if( !this.invoiceName ) {
                return false;
            } else {
                this.invoiceName = this.invoiceName.toUpperCase();
            }


            axios.post(
                '/api/check_invoice',
                {
                    invoiceName: this.invoiceName,
                    dates: this.dates,
                    weekNumber: this.weekNumber,
                    company_id: this.shippingLine,
                }
            ).then(response => {
                console.log(response.data)
                if(response.status == 200) {
                    if(response.data.length > 0) {
                        alert('The InvoiceName is already existing in the table...');
                        this.tab = 1;
                    } else {
                        alert('register the invoice...');
                        this.insertInvoice();
                        return false;
                    }
                }
            }).catch(error => {
                console.log(error);
            });


        },
        insertInvoice: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/insert_invoice',
                {
                    invoiceName: this.invoiceName,
                    dates: this.dates,
                    weekNumber: this.weekNumber,
                    company_id: this.shippingLine,
                }
            ).then(response => {
                if( response.data == 'ok' ) {
                    this.downloading = false;
                    this.overlay = false;
                    alert('The Invoice # has been registered successfully!');
                    this.getInvoices();
                    this.tab = 1;
                } else {
                    alert('Failed to register the Invoice #...please try again...');
                }
            }).catch(error => {
                console.log(error);
            });
        },
        openInvoiceNameEditDialog: function(item) {
            this.invoiceNameEditDialog = true;
            this.currentInvoiceId = item.invoice_id;
            this.invoiceName = item.invoice_name;
            this.invoiceNumberSurveyDaf1 = item.invoice_number_survey_daf1;
            this.invoiceNumberSurveyDaf2 = item.invoice_number_survey_daf2;
            this.invoiceNumberWashing1 = item.invoice_number_washing1;
            this.invoiceNumberWashing2 = item.invoice_number_washing2;
            this.invoiceNumberRepair1 = item.invoice_number_repair1;
            this.invoiceNumberRepair2 = item.invoice_number_repair2;
        },
        updateInvoiceNumbers: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/update_invoice_numbers',
                {
                    invoice_id: this.currentInvoiceId,
                    invoice_name: this.invoiceName,
                    invoice_number_survey_daf1: this.invoiceNumberSurveyDaf1,
                    invoice_number_survey_daf2: this.invoiceNumberSurveyDaf2,
                    invoice_number_washing1: this.invoiceNumberWashing1,
                    invoice_number_washing2: this.invoiceNumberWashing2,
                    invoice_number_repair1: this.invoiceNumberRepair1,
                    invoice_number_repair2: this.invoiceNumberRepair2,
                }
            ).then(response => {
                if( response.status == 200 ) {
                    this.getInvoices();
                    this.invoiceNameEditDialog = false;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        downloadInvoice: function(item) {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/download_invoice',
                {
                    date_from: item.date_from,
                    date_to: item.date_to,
                    weekNumber: item.week_no,
                    company_id: item.company_id,
                    invoice_number_survey_daf1: item.invoice_number_survey_daf1,
                    invoice_number_survey_daf2: item.invoice_number_survey_daf2,
                    invoice_number_washing1: item.invoice_number_washing1,
                    invoice_number_washing2: item.invoice_number_washing2,
                    invoice_number_repair1: item.invoice_number_repair1,
                    invoice_number_repair2: item.invoice_number_repair2,

                }
            ).then(response => {
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = this.shippingLine + 'Invoice' + moment().format('YYYYMMDD') +'.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            });
        },
        toggleVoid: function(item) {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/toggle_void',
                {
                    invoice_id: item.invoice_id,
                    void: item.void,
                }
            ).then(response => {
                this.getInvoices();
            }).catch(error => {
                console.log(error);
            });
        },
        changePage: function() {
            this.getTransactionsForInvoices();
            this.getInvoices();
        },
    },
    watch: {
        shippingLine: function() {
            this.getTransactionsForInvoices();
            this.getInvoices();
        },
        dates: function() {
            if (this.dates.length == 0) {
                return false;
            } else if ( this.dates.length == 1 ) {
                // console.log(moment(this.dates[0]).day("Sunday").format('YYYY-MM-DD'))
                // console.log(moment(this.dates[0]).day("Saturday").format('YYYY-MM-DD'))
                this.weekNumber = moment(this.dates[0]).week();
                this.dates[0] = moment(this.dates[0]).day("Sunday").format('YYYY-MM-DD');
                this.dates[1] = moment(this.dates[0]).day("Saturday").format('YYYY-MM-DD');
            } else if ( this.dates.length == 2 ) {
                return false;
            }

            this.getTransactionsForInvoices();

        },
        tab: function() {
            if ( this.tab == 0 ) {
                // console.log(0);
            } else if ( this.tab == 1 ) {
                // console.log(1);
            }
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        }
    },
}
</script>
